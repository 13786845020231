(function ($) {
  function setBg(container, img) {
    var mobile = window.matchMedia('(max-width: 767px)').matches;
    var css = {
      "background-image": "none",
      "min-height": "0",
      "background-size": "cover"
    };
    //if (!mobile) {
    css['background-image'] = "url('".concat(img.src, "')");
    css['background-repeat'] = 'no-repeat';
    // CSS takes care of background image resizing. Scale min-height according to window width.
    var imageWidth = img.width;
    var windowWidth = $(window).width();
    var scaleFactor = windowWidth / imageWidth;
    css['min-height'] = img.height * scaleFactor;
    // }
    container.css(css);
  }
  window.DGS.OnLoad.getInstance().register(function () {
    $(".two-section-grid").each(function () {
      var $self = $(this);
      var bgImgSrc = $self.data("background-image");
      if (bgImgSrc) {
        var img = new Image();
        img.onload = function () {
          setBg($self, img);
          DGS.Resizer.getInstance().register(function () {
            return setBg($self, img);
          });
        };
        img.src = bgImgSrc;
      }
    });
  });
})(jQuery);