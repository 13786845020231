var setupErrorMessage = function setupErrorMessage(component) {
  var dictionary = {
    VideoSpot: {
      Header: 'Error',
      Description: 'In order to display videos you need to accept the marketing cookies',
      ButtonLabel: 'Change cookie settings'
    }
  };
  var language = $('html').attr('lang');
  var buttonHandler = function buttonHandler(e) {
    e.preventDefault();
    window.CookieConsent.renew();
  };
  window.DGS.Dictionary.getInstance().getTexts(dictionary, function () {
    $(component).append([$('<div/>', {
      'class': 'video-spot-error-msg'
    }).append([$('<h5/>', {
      'class': 'video-spot-error-msg-header',
      'text': dictionary.VideoSpot.Header
    }), $('<p/>', {
      'class': 'video-spot-error-msg-desc',
      'text': dictionary.VideoSpot.Description
    }), $('<button/>', {
      'id': 'video-spot-error-msg-btn',
      'text': dictionary.VideoSpot.ButtonLabel,
      'click': function click(e) {
        return buttonHandler(e);
      }
    })])]);
  }, language);
};
export default setupErrorMessage;