(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.spot-control-spot').each(function () {
      var $this = $(this),
        $title = $('> h3', $this),
        $spotControls = $('.spot-controls', $this),
        hasBeenSeen = false,
        $componentContent = $('.component-content', $this),
        selector = $this.data('selector'),
        $targets = $(selector),
        $spotControl = $('.spot-control', $this),
        $inputs = $('input', $this),
        $transport = $('.spot-controls.transport', $this),
        $reset = $('reset', $transport),
        currentGroup = "twain",
        nextGroup,
        groups = [],
        currentLabelParts,
        currentGroupMembers;
      if ($title.text().indexOf('style explorer') < 0) {
        $title.text($title.text() + ' - style explorer');
      }
      if (!$transport.length) {
        $componentContent.append('<div class="spot-controls transport"></div>');
        $transport = $('.spot-controls.transport', $this);
      }
      if (!$reset.length) {
        $transport.append('<div class="reset"><a href="#">Reset styles</a></div>');
        $reset = $('reset', $transport);
      }
      $inputs.each(function () {
        var $this = $(this);
        $this.change(function () {
          var $this = $(this),
            id = $this.attr('id');
          if (this.checked) {
            $targets.addClass(id);
          } else {
            $targets.removeClass(id);
          }
          $(window).trigger('resize');
        });
      });
      $spotControl.each(function () {
        var $this = $(this),
          $label = $('.spot-control-label', $this),
          labelText = $label.text(),
          labelParts = labelText.split(' '),
          i;
        if (currentLabelParts == undefined) {
          currentLabelParts = labelParts;
          currentGroupMembers = 1;
        } else {
          for (i = 0; i < labelParts.length; i++) {
            if (labelParts[i] != currentLabelParts[i]) {
              if (i > 1) {
                if (currentGroupMembers == 1) {
                  currentLabelParts = currentLabelParts.slice(0, i);
                  groups.push(currentLabelParts.join(' '));
                }
                currentGroupMembers++;
              } else {
                currentLabelParts = labelParts;
                currentGroupMembers = 1;
              }
              break;
            }
          }
        }
      });
      $spotControl.each(function () {
        var $this = $(this),
          $label = $('.spot-control-label', $this),
          labelText = $label.text();
        if (groups.length > 0) {
          nextGroup = groups[0];
        }
        if (labelText.startsWith(nextGroup)) {
          currentGroup = groups.shift();
          nextGroup = "twain";
          $this.before('<h4>' + currentGroup + '</h4>');
          $label.text(labelText.substr(currentGroup.length + 1));
        } else if (labelText.startsWith(currentGroup)) {
          $label.text(labelText.substr(currentGroup.length + 1));
        } else {
          $this.before('<h4>' + $label.text() + '</h4>');
          $label.text(labelText.substr(labelText.lastIndexOf(' ')));
        }
      });
      $('.reset, .reset a', $this).click(function (event) {
        event.preventDefault();
        $inputs.attr('checked', false);
        $inputs.trigger('change');
      });
      /*
                  window.DGS.Scroller.getInstance().register(function (windowScroll) {
                      if(windowScroll > 100) {
                          $this.addClass('scrolled');
                      } else {
                          $this.removeClass('scrolled');
                      }
                  });
        */

      $title.click(function () {
        $('.spot-controls', $this).toggle();
        $this.toggleClass('expanded');
        $this.toggleClass('open-interface');
      });
    });
  });
})(jQuery);