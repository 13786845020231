/**
 * Created by mady on 12-09-2016.
 */
(function ($) {
  'use strict';

  function replaceSelects() {
    $('select').each(function () {
      var $this = $(this),
        curValue = $this.val(),
        id = $this.id !== undefined ? ' id="' + this.id + '"' : '',
        // get id of select tag
        name = $this.attr('name') !== undefined ? ' name="' + $this.attr('name') + '"' : '',
        // get name of select tag
        classList = $this.attr('class') !== undefined ? ' class="' + $this.attr('class') + '"' : '',
        // get all classes applied to select tag
        $hiddenInput = $('<input type="hidden"' + id + name + classList + ' />'),
        // create hidden input field with id, name and classes from select tag
        $options = $('option', $this),
        // get all options from select
        $container,
        $outer,
        $inner;
      $this.after('<div class="custom-select-container"></div>');
      $container = $('.custom-select-container', $this.parent());
      $container.append($hiddenInput);

      // create outer container
      $container.append('<div class="select-outer"></div>');
      $outer = $('.select-outer', $container);

      // create item for holding selected value
      $outer.append('<div class="select-item selected"></div>');

      // create inner container
      $container.append('<div class="select-inner"><div class="select-inner-item-container"></div><div class="select-inner-bottom"></div></div>');
      $inner = $('.select-inner-item-container', $container);
      // create option divs
      $options.each(function () {
        var $this = $(this),
          text = $this.text(),
          actualValue = $this.attr('value') !== undefined ? $this.attr('value') : text,
          value = ' data-value="' + actualValue + '"',
          $elem = $('<div class="select-item"' + value + '>' + text + '</div>');
        $.each(this.attributes, function (index, attribute) {
          $elem.attr(attribute.name, attribute.value);
        });
        $inner.append($elem);
        if (actualValue == curValue) {
          setSelectedItem(actualValue, $container);
        }
      });
      $this.remove();
      $outer.click(function () {
        if (!$(this).hasClass("disabled")) {
          if ($('.select-item', $inner).length > 5) {
            $container.addClass('scroll');
          }
          if ($container.hasClass('open')) {
            $('.custom-select-container').removeClass('open');
          } else {
            $('.custom-select-container').removeClass('open');
            $container.addClass('open');
          }
          $(".select-item", $inner).off('click');
          $(".select-item", $inner).click(function () {
            $container.removeClass('open');
            setSelectedItemValue($(this), $container);
          });
        }
      });
    });
  }
  function resetValue($customSelect) {
    $(".select-item.selected", $customSelect).attr('data-value', null);
    $(".select-item.selected", $customSelect).text('');
  }
  function setSelectedItemValue($item, $customSelect) {
    var $selectedItem = $(".select-item.selected", $customSelect),
      $hiddenInput = $('input', $customSelect);
    $.each($item[0].attributes, function (index, attribute) {
      if (attribute.name.indexOf('data') > -1) $selectedItem.attr(attribute.name, attribute.value);
    });
    $selectedItem.text($item.text());
    $hiddenInput.val($selectedItem.attr('data-value') !== undefined ? $selectedItem.attr('data-value') : $selectedItem.text());
    if ($customSelect.data('onChangeHandler') !== undefined) {
      $customSelect.data('onChangeHandler').apply($customSelect, null);
    }
  }
  function setSelectedItem(value, $customSelect) {
    $(".select-item:not('.selected')", $customSelect).each(function () {
      var $this = $(this);
      if ($this.attr('data-value') === value || $this.text() === value) {
        setSelectedItemValue($this, $customSelect);
        return true;
      }
    });
    return false;
  }
  function setSelectedItemIndex(value, $customSelect) {
    if (value === parseInt(value, 10)) {
      var $items = $(".select-item:not('.selected')", $customSelect);
      if (value < $items.length) {
        setSelectedItemValue($($items[value]), $customSelect);
        return true;
      }
    }
    return false;
  }
  /*
  window.DGS.Event.getInstance().on(window.DGS.Events.SELECT_ADDED, function(){
      replaceSelects();
  });
  */
  $.fn.extend({
    customSelectResetValue: function customSelectResetValue() {
      resetValue($(this));
    },
    customSelectGetValue: function customSelectGetValue() {
      return $('input', $(this)).val();
    },
    customSelectSetValue: function customSelectSetValue(value) {
      $(this).each(function () {
        if (!setSelectedItem(value, $(this))) {
          if (!setSelectedItemIndex(value, $(this))) {
            return false;
          }
        }
      });
      return true;
    },
    customSelectChange: function customSelectChange(callbackHandler) {
      $(this).data('onChangeHandler', callbackHandler);
    },
    customSelectOptions: function customSelectOptions(options) {
      var $this = $(this);
      $('.select-item:not(.selected)', $this).remove().end();
      options.forEach(function (val) {
        $('.select-inner-item-container', $this).append('<div class="select-item">' + val + '</div>');
      });
    },
    customSelectDisabled: function customSelectDisabled(boolValue) {
      var $this = $(this);
      if (boolValue) {
        $this.addClass("disabled");
        $('.select-outer', $this).addClass("disabled");
      } else {
        $this.removeClass("disabled");
        $('.select-outer', $this).removeClass("disabled");
      }
    }
  });
})(jQuery);