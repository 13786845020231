/**
 * Created by mady on 26-04-2017.
 */
(function ($) {
  var socialSharingConfig = {
    layout: "float",
    insertAfter: "#content",
    serviceElementsHeaderClass: "service-elements-header",
    serviceElementClass: "service-element",
    shareWindowDimensions: "width=626,height=436"
  };
  DGS.useCustomSelect(function () {
    if (window.DGS.UserAgent.getInstance().mobileTabletCheck()) {
      var $parent,
        $selects = $("select");
      $selects.each(function () {
        $parent = $(this).parent();
        $parent.addClass("add-arrow-for-mobile");
        $parent.css("position", "relative");
      });
    }
  });
  var initializeScrollFeature = function initializeScrollFeature() {
    // SETUP GLOBAL A TAG ANCHOR SCROLLING
    $('a[href*="#"]').each(function () {
      var href = $(this).attr("href");
      if (href !== undefined && href.indexOf("#") !== -1) {
        $(this).on("click", function (e) {
          var href = $(this).attr("href"),
            targetName = href.substr(href.indexOf("#") + 1),
            $target = $('a[name="' + targetName + '"]').length ? $('a[name="' + targetName + '"]') : $('a[id="' + targetName + '"]').length ? $('a[id="' + targetName + '"]') : $('div[id="' + targetName + '"]'),
            position = $target.position(),
            top = position ? position.top : null,
            $submenuSpot = $(".component.submenu-spot"),
            $submenu = $submenuSpot.length ? $submenuSpot : $(".component.richtext-submenu"),
            submenuHeight = 0,
            stickyNavigation = $(".sticky-navigation").css("display") === "none" ? false : true;
          top = $('a[id="' + targetName + '"]').length ? top - 10 : top;
          if ($(".sticky-navigation").length && stickyNavigation) {
            submenuHeight = ($submenu.length ? $submenu.outerHeight() : 0) + ($(".sticky-navigation").length ? $(".sticky-navigation").outerHeight() : 0) + top;
          } else {
            submenuHeight = $submenu.length ? $submenu.outerHeight() : 0;
            submenuHeight = top + submenuHeight;
          }
          if (targetName != "" && top != null) {
            e.preventDefault();
            $("html, body").animate({
              scrollTop: submenuHeight
            }, 600);
          }
        });
      }
    });
    if ($("#social-sharing").length) {
      $("#social-sharing").sharingTools({
        layout: socialSharingConfig.layout,
        insertAfter: socialSharingConfig.insertAfter,
        serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
        serviceElementClass: socialSharingConfig.serviceElementClass,
        shareWindowDimensions: socialSharingConfig.shareWindowDimensions
      });
      //if social media icons are present add identifying class to cookie-warning for manipulation purposes
      $(".component.cookie-warning").addClass("so-me");
    }
    window.DGS.Resizer.getInstance().register(function () {
      if ($("#social-sharing").hasClass("float")) {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO FOOTER
        var mq = window.matchMedia("(min-width: 992px)"); //OTICON SPECIFIC WIDTH
        var socialSharingHeight = 0;
        if (mq.matches) {} else {
          socialSharingHeight = $("#social-sharing.float").outerHeight();
        }
        $("#footer").css("margin-bottom", socialSharingHeight);
      }
    });
  };
  window.addEventListener("load", function () {
    setTimeout(function () {
      initializeScrollFeature();
    }, 0);
  });
})(jQuery, window.DGS);