function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
if (document.querySelector('.list-overview-spot')) {
  var onClickEntireItem = function onClickEntireItem(e, linkHref, targetAttribute) {
    // trigger click event on left and middle mouse button
    if (_typeof(e) === 'object' && e.button !== 2 && e.target.tagName !== 'A') {
      if (targetAttribute) {
        window.open(linkHref, targetAttribute);
      } else {
        window.location.href = linkHref;
      }
    }
  };
  var decoratePdfLink = function decoratePdfLink(linkHref, anchorLink) {
    if (linkHref !== null && linkHref !== void 0 && linkHref.includes('.pdf')) {
      anchorLink === null || anchorLink === void 0 || anchorLink.classList.add('download-link');
    }
  };
  document.querySelectorAll('.list-overview-spot .element').forEach(function (item) {
    var anchorLink = item.querySelector('a');
    var linkHref = anchorLink === null || anchorLink === void 0 ? void 0 : anchorLink.getAttribute('href');
    var targetAttribute = anchorLink === null || anchorLink === void 0 ? void 0 : anchorLink.getAttribute('target');
    if (linkHref) {
      item.addEventListener('mouseup', function (e) {
        return onClickEntireItem(e, linkHref, targetAttribute);
      });
    }
    decoratePdfLink(linkHref, anchorLink);
  });
}