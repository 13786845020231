// Import the base functionality
import { WDHPlainTextSpot, WDHMultiComponentTabNav } from "../../../../wdh-feature/wdh-plain-text-spot";
(function (DGS) {
  var plainTextSpot = new WDHPlainTextSpot();
  var multiComponentTabNav = new WDHMultiComponentTabNav();
  plainTextSpot.registerVariation(multiComponentTabNav);
  plainTextSpot.run();
  DGS.OnLoad.getInstance().register(function () {
    function reportNumberOfEntriesInDataLists() {
      var datalists = multiComponentTabNav.getManagedComponents();
      var index = 0;
      datalists.forEach(function (node) {
        var rows = Array.from(node.querySelectorAll('.data-list-spot__row'));
        multiComponentTabNav.appendTextToTab(index, " (".concat(rows.length, ")"));
        index += 1;
      });
    }
    window.DGS.Event.getInstance().on('dataResultEmpty', function (identifier) {
      reportNumberOfEntriesInDataLists();
    });
    window.DGS.Event.getInstance().on('dataResultReady', function (identifier, data) {
      reportNumberOfEntriesInDataLists();
    });
  });
})(window.DGS);