import setupErrorMessage from './cookie-consent-error';
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $(document).ready(function () {
      var $videoPlayer = $('.wrapper .content .component.video-player');
      var handleMarketingConsent = function handleMarketingConsent(component) {
        if (window.CookieInformation && !window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
          $(component).addClass('no-marketing-cookie');
          $(component).find('iframe').removeClass('iframe-loaded');
        } else {
          $(component).removeClass('no-marketing-cookie');
          $(component).find('iframe').addClass('iframe-loaded');
        }
      };
      $videoPlayer.each(function (index, item) {
        setupErrorMessage($(item).find('.video-player-iframe'));
        handleMarketingConsent(item);
        setTimeout(function () {
          $('.coi-banner__accept, .coi-banner__decline').on('click', function () {
            handleMarketingConsent(item);
          });
        }, 500);
      });
    });
  });
})(jQuery);