if (document.querySelector('.sticky-banner')) {
  var STICKY_BANNER_CLASS = '.flex-spot.sticky-banner';
  var STICKY_CLASS = 'sticky-active';
  var DISTANCE_TO_ELEMENT = -300;
  DGS.Scroller.getInstance().register(function () {
    var _$element$getBounding;
    var $element = document.querySelector(STICKY_BANNER_CLASS);
    var topPosition = $element === null || $element === void 0 || (_$element$getBounding = $element.getBoundingClientRect()) === null || _$element$getBounding === void 0 ? void 0 : _$element$getBounding.top;
    setTimeout(function () {
      if ($element && topPosition && topPosition > DISTANCE_TO_ELEMENT) {
        $element.classList.remove(STICKY_CLASS);
      } else {
        $element.classList.add(STICKY_CLASS);
      }
    }, 20);
  });
}