(function ($) {
  "use strict";

  var DGS = window.DGS;
  DGS.GTMTracking = DGS.GTMTracking || {
    getDataLayer: function getDataLayer() {
      if (typeof dataLayer !== 'undefined') {
        return dataLayer;
      } else {
        return {
          push: function push(data) {
            console.log('GTM not implemented. Debug data: ', data);
          }
        };
      }
    }
  };
  var GA_EVENT_NON_INT = 'GAeventNonInt',
    GA_EVENT = 'GAevent',
    SCROLL_TRACKING = 'Scroll tracking',
    COMPONENT_TRACKING = 'Component tracking',
    NAVIGATION = 'Navigation',
    OTHER_CLICK = 'Other Clicks',
    BOOK_APPOINTMENT = 'Book Appointment',
    VIDEO = 'Video',
    DOWNLOAD = 'Download',
    HCL = 'HCL',
    INTERNAL_SEARCH = 'Internal Search',
    ERROR_PAGE = '404 page',
    FORM_ERRORS = 'Form Fill Errors',
    buildEvent = function buildEvent(event, category, action, label) {
      return {
        event: event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      };
    },
    getPageName = function getPageName() {
      var pageName = window.location.pathname;
      if (pageName != "/" && pageName.substr(pageName.length - 1) == "/") {
        pageName = pageName.substr(0, pageName.length - 1);
      }
      return pageName;
    },
    getDataLayer = function getDataLayer() {
      return window.DGS.GTMTracking.getDataLayer();
    },
    getSearchTerm = function getSearchTerm() {
      return window.DGS.getQueryParameter('q');
    };

  // Tracking 5.1 Page loaded - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackPageLoad', function () {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, SCROLL_TRACKING, getPageName(), 'Page loaded'));
  });
  // Tracking 5.2 25% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackPage25Reached', function () {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, SCROLL_TRACKING, getPageName(), '25 % reached'));
  });
  // Tracking 5.3 50% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackPage50Reached', function () {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, SCROLL_TRACKING, getPageName(), '50 % reached'));
  });
  // Tracking 5.4 75% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackPage75Reached', function () {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, SCROLL_TRACKING, getPageName(), '75 % reached'));
  });
  // Tracking 5.5 Bottom reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackPage100Reached', function () {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, SCROLL_TRACKING, getPageName(), '100 % reached'));
  });
  // Tracking 5.6 Certain element reached
  window.DGS.Event.getInstance().on('trackElementReached', function (nameOfComponent) {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, COMPONENT_TRACKING, getPageName(), nameOfComponent));
  });

  // Tracking 6.1 Main menu - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackMainMenuNav', function (nameOfLink) {
    getDataLayer().push(buildEvent(GA_EVENT, NAVIGATION, 'Main menu', nameOfLink));
  });
  // Tracking 6.2 Section menu - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackSectionMenuNav', function (sectionName, nameOfLink) {
    getDataLayer().push(buildEvent(GA_EVENT, NAVIGATION, 'Section menu - ' + sectionName, nameOfLink));
  });
  // Tracking 6.3 Footer - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackFooterNav', function (nameOfLink) {
    getDataLayer().push(buildEvent(GA_EVENT, NAVIGATION, 'Footer', nameOfLink));
  });
  // Tracking 6.4 Other - SEMI-IMPLEMENTED
  window.DGS.Event.getInstance().on('trackOtherNav', function (linkText, linkUrl) {
    getDataLayer().push(buildEvent(GA_EVENT, OTHER_CLICK, linkText, linkUrl));
  });

  // Tracking 7.1 Video play - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackVideoPlay', function (videoName) {
    getDataLayer().push(buildEvent(GA_EVENT, VIDEO, videoName, 'Play'));
  });
  // Tracking 7.2 Video 25% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackVideo25Reached', function (videoName) {
    getDataLayer().push(buildEvent(GA_EVENT, VIDEO, videoName, '25 % reached'));
  });
  // Tracking 7.3 Video 50% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackVideo50Reached', function (videoName) {
    getDataLayer().push(buildEvent(GA_EVENT, VIDEO, videoName, '50 % reached'));
  });
  // Tracking 7.4 Video 75% reached - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackVideo75Reached', function (videoName) {
    getDataLayer().push(buildEvent(GA_EVENT, VIDEO, videoName, '75 % reached'));
  });
  // Tracking 7.5 Video finished - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackVideo100Reached', function (videoName) {
    getDataLayer().push(buildEvent(GA_EVENT, VIDEO, videoName, '100 % reached'));
  });

  // Tracking 8 Downloads - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackDownload', function (fileType, fileName) {
    getDataLayer().push(buildEvent(GA_EVENT, DOWNLOAD, fileType, fileName));
  });

  // Tracking 9.1 HCL search - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLSearch', function (searchTerm) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Search', searchTerm.toLowerCase()));
  });
  // Tracking 9.2 HCL Let oticon use your location - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLAllowLocation', function () {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Search – use location', 'Accepted'));
  });
  // Tracking 9.3.1 Opening clinic lightbox - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLClinicView', function (clinicName) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic view', clinicName));
  });
  // Tracking 9.3.2 Clicking on phone number - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLClinicPhone', function (clinicName) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic - Phone', clinicName));
  });
  // Tracking 9.3.3 Clicking on email - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLClinicEmail', function (clinicName) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic - Email', clinicName));
  });
  // Tracking 9.3.4 Clicking on website - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLClinicWWW', function (clinicName) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic - Website', clinicName));
  });
  // Tracking 9.3.5 Clicking on driving instructions - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackHCLClinicDriving', function (clinicName) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic - Driving instructions', clinicName));
  });

  // Tracking 11.1 Refined searches -- NOT NEEDED since all searches result in page view
  // Tracking 11.2 Searches with no results - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackSearchNoResults', function () {
    getDataLayer().push(buildEvent(GA_EVENT, INTERNAL_SEARCH, 'No result', getSearchTerm()));
  });
  // Tracking 11.3 Searches with results - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackSearchResults', function () {
    getDataLayer().push(buildEvent(GA_EVENT, INTERNAL_SEARCH, 'Results', getSearchTerm()));
  });
  // Tracking 11.4 Click on links on no results page - IMPLEMENTED
  window.DGS.Event.getInstance().on('trackSearchDefaultLinks', function (linkName) {
    getDataLayer().push(buildEvent(GA_EVENT, INTERNAL_SEARCH, 'Click on link on no result page', linkName));
  });

  // Tracking 12 404 pages - IMPLEMENTED
  window.DGS.Event.getInstance().on('track404Page', function (urlRequested, referrer) {
    getDataLayer().push(buildEvent(GA_EVENT_NON_INT, ERROR_PAGE, urlRequested, referrer));
  });

  // Tracking Center Locator feature component events
  window.DGS.Event.getInstance().on('trackCLCenterPhoneClick', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Info Click - Phone Number', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLCenterClickFromList', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic Click - List', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLFindDirectionsClick', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Info Click - Get Directions', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLCenterClickFromMap', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic Click - Map', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLBookAppointmentClick', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Book Appointment Click', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLCenterSearchField', function (query) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Search Clinic - Results', query));
  });
  window.DGS.Event.getInstance().on('trackCLCenterSearchFieldNoResults', function (query) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Search Clinic - No results', query));
  });
  window.DGS.Event.getInstance().on('trackCLCenterDetailsClick', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Info Click - Clinic Details', centerData));
  });
  window.DGS.Event.getInstance().on('trackCLCenterClickByExternalCode', function (centerData) {
    getDataLayer().push(buildEvent(GA_EVENT, HCL, 'Clinic Click', centerData));
  });

  // Tracking Online Booking feature component events
  window.DGS.Event.getInstance().on('trackOBSelectCenter', function (centerName) {
    getDataLayer().push(buildEvent(GA_EVENT, BOOK_APPOINTMENT, 'Select Center', centerName));
  });
  window.DGS.Event.getInstance().on('trackOBSelectDate', function (centerName, appointmentType) {
    getDataLayer().push(buildEvent(GA_EVENT, BOOK_APPOINTMENT, 'Select Date - ' + appointmentType, centerName));
  });
  window.DGS.Event.getInstance().on('trackOBSelectTime', function (centerName, appointmentType) {
    getDataLayer().push(buildEvent(GA_EVENT, BOOK_APPOINTMENT, 'Select Time - ' + appointmentType, centerName));
  });
  window.DGS.Event.getInstance().on('trackOBFormFillSuccess', function (centerName, appointmentType) {
    getDataLayer().push(buildEvent(GA_EVENT, BOOK_APPOINTMENT, 'Form fill success - ' + appointmentType, centerName));
  });
  window.DGS.Event.getInstance().on('trackOBFormFillError', function (centerName, appointmentType) {
    getDataLayer().push(buildEvent(GA_EVENT, BOOK_APPOINTMENT, 'Form fill error - ' + appointmentType, centerName));
  });

  // Tracking Form feature component events
  window.DGS.Event.getInstance().on('trackFValidationErrors', function (formName, formErrors) {
    getDataLayer().push(buildEvent(GA_EVENT, FORM_ERRORS, formName, formErrors));
  });
  window.DGS.OnLoad.getInstance().register(function () {
    var scrollTrack = [{
      h: 0,
      e: 'trackPageLoad',
      done: false
    }, {
      h: 0.25,
      e: 'trackPage25Reached',
      done: false
    }, {
      h: 0.5,
      e: 'trackPage50Reached',
      done: false
    }, {
      h: 0.75,
      e: 'trackPage75Reached',
      done: false
    }, {
      h: 1,
      e: 'trackPage100Reached',
      done: false
    }];
    window.DGS.Scroller.getInstance().register(function (scrollTop) {
      var scrollHeight = $('body')[0].scrollHeight,
        windowHeight = $(window).height(),
        maxScroll = scrollHeight - windowHeight,
        percent = scrollTop / maxScroll,
        i,
        scrollTrackObj;
      for (i = scrollTrack.length - 1; i >= 0; i--) {
        scrollTrackObj = scrollTrack[i];
        if (percent >= scrollTrackObj.h && !scrollTrackObj.done) {
          window.DGS.Event.getInstance().trigger(scrollTrackObj.e);
          scrollTrackObj.done = true;
          scrollTrack.splice(i, 1);
        }
      }
    });

    // Tracking 6.1
    $('#wrapper .component.link-list.nav li a').click(function () {
      var linkName = $(this).text();
      window.DGS.Event.getInstance().trigger('trackMainMenuNav', linkName);
    });
    // Tracking 6.2
    $('#wrapper .component.richtext-submenu .richtext-submenu-columns .richtext-submenu-column a').click(function () {
      var sectionName = $('#wrapper .component.richtext-submenu .submenu-expander').text(),
        linkName = $(this).closest('.richtext-submenu-column').find('h3').text() + ' - ' + $(this).text();
      window.DGS.Event.getInstance().trigger('trackSectionMenuNav', sectionName, linkName);
    });
    // Tracking 6.3
    $('#wrapper #footer .component.rich-text-list.footer-fix a').click(function () {
      var sectionName = $('h3', $(this).closest('ul').closest('li')).text(),
        sectionName = sectionName !== '' ? sectionName : $('h3', $(this).closest('li')).text(),
        linkName = $(this).text();
      window.DGS.Event.getInstance().trigger('trackFooterNav', sectionName + ' - ' + linkName);
    });
    // Tracking 6.4
    $('#wrapper a.track, #wrapper a.link-track').click(function () {
      window.DGS.Event.getInstance().trigger('trackOtherNav', $(this).text(), $(this).attr('href'));
    });
    $('#wrapper a.track-download, #wrapper a.link-track-download').click(function () {
      window.DGS.Event.getInstance().trigger('trackOtherNav', $(this).text(), $(this).attr('href'));
    });

    // Tracking 11.4
    $('#wrapper .component.no-search-result .component-content a').click(function () {
      window.DGS.Event.getInstance().trigger('trackSearchDefaultLinks', $(this).text());
    });

    // Tracking 12
    var is404Page = $('body').hasClass('is404page'),
      urlRequested = document.location.href,
      referrer = document.referrer;
    if (is404Page) {
      referrer = referrer !== '' ? referrer : '-';
      window.DGS.Event.getInstance().trigger('track404Page', urlRequested, referrer);
    }
  });
})(jQuery);
var test = 'test';