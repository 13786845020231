require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
import $ from 'jquery';
import cssCustomLoader from './lazy-loading/cssCustomLoader';
cssCustomLoader();
window.jQuery = $;
window.$ = $;
require('./project/ie-flatten-polyfill.js');
require('../../../wdh-foundation/dateformat/dateformat.js');
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-sharing/dgs-sharing.js');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/legacy/googlemaps');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('./lazy-loading/jsCustomLoader');

// PROJECT

require('./project/base-main-custom.js');
require('./project/tracking-gtm.js');
require('./project/component-singlesignon.js');
require('./project/custom-footer.js');
require('./project/custom-select.js');
require('./project/login.js');
require('./project/main.js');
require('./project/header/index');

// FEATURE
require('./feature/component-intro-banner.js');
require('./feature/component-spot-control-spot.js');
require('./feature/component-submenu.js');
require('./feature/component-tile.js');
require('./feature/component-two-section-grid.js');
require('./feature/component-video-spot.js');
require('./feature/component-alternative-url.js');
require('./feature/component-box-spot.js');
require('./feature/component-color-selector-spot.js');
require('./feature/component-comments.js');
require('./feature/component-contact-information.js');
require('./feature/component-geo-ip-prompt');
require('./feature/component-option-spot');
require('./feature/component-text-image-spot.js');
require('./feature/wdh-image-spot');
require('./feature/wdh-rich-text');
require('./feature/wdh-plain-text-spot');
require('./feature/component-video-player');
require('./feature/components-pep/pep-others');
require('./feature/scroll-to-top');
require('../custom-components/flex-spot/list-overview-spot/index');
require('./feature/center-regions-spot'); // need to load after main

require('@demant/wdh-gdpr/lib/default.implementation.js');
require('../custom-components/ebusiness-dashboard/dashboard/js');
require('../custom-components/flex-spot/custom-pop-ups/js/sticky-banner');
    if (document.querySelector('.accordion-spot:not(.tabs)')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'./feature/components-pep/pep-accordions')
        .then(function (foo) { console.log('.accordion-spot:not(.tabs) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .accordion-spot:not(.tabs)'); console.log(error); });
    }
    if (document.querySelector('.get-accordion-items')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'./feature/components-pep/pep-accordions')
        .then(function (foo) { console.log('.get-accordion-items LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .get-accordion-items'); console.log(error); });
    }
    if (document.querySelector('.component.locations-spot')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'./feature/component-locations-spot/component-locations-spot')
        .then(function (foo) { console.log('.component.locations-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .component.locations-spot'); console.log(error); });
    }
    if (document.querySelector('.flex-spot.general-oticon-more.custom.ver2')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'../custom-components/flex-spot/oticon-more-lp-v2/index')
        .then(function (foo) { console.log('.flex-spot.general-oticon-more.custom.ver2 LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .flex-spot.general-oticon-more.custom.ver2'); console.log(error); });
    }
    if (document.querySelector('iframe')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'./feature/component-iframe')
        .then(function (foo) { console.log('iframe LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for iframe'); console.log(error); });
    }
    if (document.querySelector('.legacy')) {
      var link = document.querySelector('link[data-component^="legacy"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.locations-spot')) {
      var link = document.querySelector('link[data-component^="locations-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.pep')) {
      var link = document.querySelector('link[data-component^="pep"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.flex-spot')) {
      var link = document.querySelector('link[data-component^="flex-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.spare-parts-spot')) {
      var link = document.querySelector('link[data-component^="spare-parts-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test')) {
      var link = document.querySelector('link[data-component^="hearing-test"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.resource-center-new')) {
      var link = document.querySelector('link[data-component^="resource-center-new"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.countdown-timer')) {
      var link = document.querySelector('link[data-component^="countdown-timer"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.batteryshop')) {
      var link = document.querySelector('link[data-component^="batteryshop"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.product-order')) {
      var link = document.querySelector('link[data-component^="product-order"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.payment-progress-spot')) {
      var link = document.querySelector('link[data-component^="payment-progress-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.data-hierarchy-spot')) {
      var link = document.querySelector('link[data-component^="data-hierarchy-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.product-tool')) {
      var link = document.querySelector('link[data-component^="product-tool"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.form')) {
      var link = document.querySelector('link[data-component^="form"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.text-image-spot')) {
      var link = document.querySelector('link[data-component^="text-image-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.data-list-spot')) {
      var link = document.querySelector('link[data-component^="data-list-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.link-list')) {
      var link = document.querySelector('link[data-component^="link-list"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.download-center')) {
      var link = document.querySelector('link[data-component^="download-center"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-center-locator')) {
      var link = document.querySelector('link[data-component^="hearing-center-locator"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.phone-compatibility')) {
      var link = document.querySelector('link[data-component^="phone-compatibility"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }