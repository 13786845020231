(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    //$('#wrapper #footer > .row > .grid-12 .component.rich-text-list > .component-content > ul').append('<li class="stretch"></li>');
    window.DGS.Justify.getInstance().apply($('#wrapper #footer > .row > .grid-12 .component.rich-text-list > .component-content > ul'), 'li');
    window.DGS.EqualHeight.getInstance().register($('.footer-fix .component-content > ul > li'), true, function () {
      setToggle();
    });
    var toogleMode = false;
    $('.component.rich-text-list.footer-fix .component-content ul li').each(function () {
      $(this).on('click', function () {
        if (toogleMode) {
          var me = $(this).find('ul');
          me.toggle();
        }
      });
    });
    $('.component.breadcrumb li:last-child').each(function () {
      if (window.location.href.indexOf('?') != -1) {
        var queryString = window.location.href.slice(window.location.href.indexOf('?') + 1);
        var _href = $(this).find('a').attr('href') + '?' + queryString;
        $(this).find('a').attr("href", _href);
      }
    });
    function setToggle() {
      if (window.matchMedia('(min-width: 600px)').matches) {
        toogleMode = false;
        $('.component.rich-text-list.footer-fix .component-content > ul > li').each(function () {
          $(this).find('ul').css('display', 'block');
        });
      } else {
        toogleMode = true;
        $('.component.rich-text-list.footer-fix .component-content > ul > li').each(function () {
          $(this).css('height', 'auto');
          $(this).find('ul').css('display', 'none');
        });
      }
    }
    $(window).resize(function () {
      setToggle();
    });
  });
})(jQuery);