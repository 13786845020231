/**
 * Created by mady on 15-12-2016.
 */
(function ($) {
  "use strict";

  var errorMessages = {
    emptyFields: "Fields cannot be empty",
    differentFields: "Invalid password or passwords are different",
    unknownId: "Id not found",
    expiredLink: "Reset Password link has expired",
    unknownError: "Unknown error",
    weakPassword: "Weak password"
  };
  var getConfigForElement = function getConfigForElement($element) {
      var $script = $('script[type="application/json"]', $element),
        config;
      config = $script.length ? JSON.parse($script.html()) : null;
      return config;
    },
    onInputKeyUp = function onInputKeyUp(event, validateFunc, submitFunc) {
      var isValid = validateFunc.apply();
      if (event.which == 13 && isValid) {
        event.preventDefault();
        submitFunc.apply();
      }
    },
    onSubmitBtnClick = function onSubmitBtnClick(event, validateFunc, submitFunc) {
      event.preventDefault();
      if (validateFunc.apply(null, [true])) {
        submitFunc.apply();
      }
    },
    submitData = function submitData(method, endpoint, data, callback) {
      $.ajax({
        url: endpoint,
        type: method,
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8"
      }).then(function (data, textState, jqXHR) {
        callback.apply(null, [jqXHR.status, data]);
      }, function (jqXHR) {
        callback.apply(null, [jqXHR.status, jqXHR.responseText]);
      });
    },
    doFieldsHaveText = function doFieldsHaveText(decorateOnError, $messagePlaceholder) {
      var $element, arg;
      for (arg = 2; arg < arguments.length; ++arg) {
        $element = arguments[arg];
        if (!$element.length || !$element.val() || $element.val().trim === '') {
          if (decorateOnError) {
            $element.addClass('error');
            if ($messagePlaceholder.length > 0) {
              $messagePlaceholder.html(errorMessages.emptyFields);
              $messagePlaceholder.parent(".alert").show();
            }
          }
          return false;
        }
      }
      return true;
    },
    areFieldsEqual = function areFieldsEqual(decorateOnError, $messagePlaceholder) {
      var curValue, lastValue, arg, $element;
      for (arg = 2; arg < arguments.length; ++arg) {
        $element = arguments[arg];
        curValue = $element.val();
        if (lastValue && curValue != lastValue) {
          if (decorateOnError) {
            $element.addClass('error');
            if ($messagePlaceholder.length > 0) {
              $messagePlaceholder.html(errorMessages.differentFields);
              $messagePlaceholder.parent(".alert").show();
            }
          }
          return false;
        }
        lastValue = curValue;
      }
      return true;
    },
    areFieldsStrongEnough = function areFieldsStrongEnough(decorateOnError, $messagePlaceholder) {
      var $element,
        arg,
        value,
        isValid = true,
        patterns = [new RegExp("[0-9]{1}", "g"), new RegExp("[^\\s]{8}", "g")];
      for (arg = 2; arg < arguments.length; ++arg) {
        $element = arguments[arg];
        value = $element.val();
        patterns.forEach(function (pattern) {
          if (!pattern.test(value)) {
            if (decorateOnError) {
              $element.addClass('error');
              if ($messagePlaceholder.length > 0) {
                $messagePlaceholder.html(errorMessages.weakPassword);
                $messagePlaceholder.parent(".alert").show();
              }
            }
            isValid = false;
          }
        });
        if (!isValid) return false;
      }
      return true;
    },
    hideFirstShowSecond = function hideFirstShowSecond($first, $second) {
      $first.hide();
      $second.show();
    };
  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.reset-password-spot').each(function () {
      var $resetPasswordSpot = $(this),
        config = getConfigForElement($resetPasswordSpot),
        $submitBtn = $('.submit', $resetPasswordSpot),
        $loader = $('.cssload-container', $resetPasswordSpot),
        $input = $('input[type="text"]', $resetPasswordSpot),
        $alert = $('.alert', $resetPasswordSpot),
        endpoint,
        returnUrl,
        validateInput,
        submitRequest;
      if (config && $input.length && $submitBtn.length) {
        endpoint = config.endpoint;
        returnUrl = config.returnUrl;
        submitRequest = function submitRequest() {
          $alert.hide();
          hideFirstShowSecond($submitBtn, $loader);
          submitData("POST", endpoint, {
            "username": $input.val(),
            "redirectUrl": returnUrl
          }, function (responseCode, responseData) {
            if (responseCode == "200" || responseCode == "201") {
              window.location = responseData;
            } else {
              $alert.show();
              hideFirstShowSecond($loader, $submitBtn);
            }
          });
        };
        validateInput = function validateInput(decorateOnError) {
          if (doFieldsHaveText(decorateOnError, $input)) {
            $input.removeClass('required');
            $input.removeClass('error');
            return true;
          } else {
            return false;
          }
        };
        $input.keyup(function (e) {
          onInputKeyUp(e, validateInput, submitRequest);
        });
        $submitBtn.click(function (event) {
          onSubmitBtnClick(event, validateInput, submitRequest);
        });
        validateInput();
      }
    });
    $('.component.set-password-spot').each(function () {
      var $setPasswordSpot = $(this),
        config = getConfigForElement($setPasswordSpot),
        $submitBtn = $('input[type="submit"]', $setPasswordSpot),
        $loader = $('.cssload-container', $setPasswordSpot),
        $input = $('input#password', $setPasswordSpot),
        $inputConfirm = $('input#password-confirm', $setPasswordSpot),
        $alert = $('.alert', $setPasswordSpot),
        $alertMessage = $('.message', $alert),
        endpoint,
        validateInput,
        submitRequest;
      if (config && $input.length && $submitBtn.length) {
        endpoint = config.endpoint;
        submitRequest = function submitRequest() {
          $alert.hide();
          hideFirstShowSecond($submitBtn, $loader);
          submitData("POST", endpoint, {
            password: $input.val(),
            confirmedpassword: $inputConfirm.val()
          }, function (responseCode, responseData) {
            if (responseCode == "200" || responseCode == "201") {
              window.location = responseData;
            } else {
              if (responseCode == "404") $alertMessage.html(errorMessages.unknownId);else if (responseCode == "400") $alertMessage.html(errorMessages.expiredLink);else $alertMessage.html(errorMessages.unknownError);
              $alert.show();
              hideFirstShowSecond($loader, $submitBtn);
            }
          });
        };
        validateInput = function validateInput(decorateOnError) {
          if (doFieldsHaveText(decorateOnError, $alertMessage, $input, $inputConfirm) && areFieldsEqual(decorateOnError, $alertMessage, $input, $inputConfirm) && areFieldsStrongEnough(decorateOnError, $alertMessage, $input)) {
            $input.removeClass('required');
            $input.removeClass('error');
            $alert.hide();
            return true;
          } else {
            return false;
          }
        };
        $input.keyup(function (e) {
          onInputKeyUp(e, validateInput, submitRequest);
        });
        $inputConfirm.keyup(function (e) {
          onInputKeyUp(e, validateInput, submitRequest);
        });
        $submitBtn.click(function (event) {
          onSubmitBtnClick(event, validateInput, submitRequest);
        });
        validateInput();
      }
    });
  });
})(jQuery);