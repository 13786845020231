function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var event = "genericGAEvent";
var _window = window,
  DGS = _window.DGS;
DGS.GTMTracking = DGS.GTMTracking || {
  getDataLayer: function getDataLayer() {
    if (typeof dataLayer !== "undefined") {
      return dataLayer;
    }
    return {
      push: function push(data) {
        console.log("GTM not implemented. Debug data: ", data);
      }
    };
  }
};
export var isDataLayer = function isDataLayer() {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer();
};
export var pushEventToDataLayer = function pushEventToDataLayer(eventData) {
  if (isDataLayer) {
    window.DGS.GTMTracking.getDataLayer().push(eventData);
  } else {
    console.log("DGS GTM not implemented. Debug data: ".concat(eventData));
  }
};
export var buildAndPushGenericGAEvent = function buildAndPushGenericGAEvent(eventCategory, eventAction, eventLabel, eventValue) {
  var eventData = {
    event: event,
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    event_value: eventValue
  };
  pushEventToDataLayer(eventData);
};

// HCL tracking
export var trackHCLSearch = function trackHCLSearch(searchTerm) {
  return buildAndPushGenericGAEvent("hcl", "search", searchTerm, "");
};
export var trackHCLFormSubmit = function trackHCLFormSubmit(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "form_submitted", clinicName, "");
};
export var trackHCLClinicClick = function trackHCLClinicClick(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "click_clinic", clinicName, "");
};
export var trackHCLClinicDriving = function trackHCLClinicDriving(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "clinic_driving_instructions", clinicName, "");
};
export var trackHCLClinicPhone = function trackHCLClinicPhone(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "clinic_phone", clinicName, "");
};
export var trackHCLClinicEmail = function trackHCLClinicEmail(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "clinic_email", clinicName, "");
};
export var trackHCLClinicWWW = function trackHCLClinicWWW(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "clinic_www", clinicName, "");
};

// Download center tracking
export var trackDownload = function trackDownload(fileType, fileName) {
  return buildAndPushGenericGAEvent("download", fileType, fileName, "");
};

// Form submit tracking
var createFormSubmitPayload = function createFormSubmitPayload(formName) {
  var clinicName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return buildAndPushGenericGAEvent("form_submitted", formName, clinicName, "");
};

// Internal search tracking
export var trackWebsiteInternalSearch = function trackWebsiteInternalSearch(hasResult) {
  var result = hasResult ? "result" : "no_result";
  var searchTerm = window.DGS.getQueryParameter("q");
  return buildAndPushGenericGAEvent("internal_search", result, searchTerm, "");
};
export var trackFormSubmit = function trackFormSubmit() {
  var clinicName = new URLSearchParams(window.location.search).get("place");
  var formName = window.location.pathname;
  if (clinicName) {
    createFormSubmitPayload(formName, clinicName);
  } else {
    createFormSubmitPayload(formName);
  }
};

// Compatibility Guide v3
export var trackCompatibilityGuideSelection = function trackCompatibilityGuideSelection(isProduct, name, noResult) {
  var productId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "" || 0;
  var device = isProduct ? "product" : "phone";
  var result = noResult ? "no_result" : "with_result";
  var eventData = _defineProperty(_defineProperty(_defineProperty({
    event: event,
    event_category: "compatibility_guide_".concat(device)
  }, "".concat(device, "_name"), name), "compatiblity_result", result), "product_id", productId);
  pushEventToDataLayer(eventData);
};
export var trackCompatibilityGuideDevicesCompatibility = function trackCompatibilityGuideDevicesCompatibility(phoneName, productName, productId, notCompatible) {
  var compatibility = notCompatible ? "not_compatible" : "compatible";
  var eventData = {
    event: event,
    event_category: "compatibility_guide_phone_product",
    product_name: productName,
    phone_name: phoneName,
    compatiblity_result: compatibility,
    product_id: productId
  };
  pushEventToDataLayer(eventData);
};
export var trackProductClick = function trackProductClick(productName, productId) {
  return buildAndPushGenericGAEvent("product_click", productName, window.location.pathname, productId);
};