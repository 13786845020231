// IE polyfills

// Adding forEach function to NodeList
if (window.NodeList && !NodeList.prototype.forEach) {
  // Disables eslint since scope of this needs to be bound
  // eslint-disable-next-line func-names
  NodeList.prototype.forEach = function (callback, thisArg) {
    var arg = thisArg || window;
    for (var i = 0; i < this.length; i += 1) {
      callback.call(arg, this[i], i, this);
    }
  };
}