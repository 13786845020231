(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $(".component.comments").each(function () {
      var $this = $(this);
      $(".component-content", $(this)).prepend($(".comments-list-content", $(this)));
      $(".comments-list-content .commentsItem").each(function () {
        $(".commentsText", $(this)).after($(".userNameText", $(this)));
        $(".commentsText", $(this)).after($("<br />"));
      });
      $(".joinconv-linkbutton", $(this)).click(function () {
        $(".anonymous-comment-form", $this).show();
        $(".comment-post", $this).show();
        return false;
      });
    });
  });
})(jQuery);