(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('<button>').attr('type', 'button').addClass('scroll-to-top').appendTo('#content');
    var $videoPlayer = $('.wrapper .content .scroll-to-top');
    $videoPlayer.click(function () {
      $('html, body').scrollTop(0);
      return false;
    });
    $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        $videoPlayer.addClass("is-visible");
      } else {
        $videoPlayer.removeClass("is-visible");
      }
    });
  });
})(jQuery);