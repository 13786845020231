var accesibility = {
  headerEl: "",
  menuItems: ".sticky-navigation .level2,.sticky-navigation .level3",
  menuLists: ".sticky-navigation ul",
  offRole: ".sticky-navigation ul li",
  closeButton: ".close-button",
  logo: ".logo-svg-oticon a",
  navExpander: ".nav-expander",
  activeSubmenu: {
    set current(element) {
      accesibility.tabIndexChanger(element);
    }
  },
  defaultDictionary: {
    Navigation: {
      openMenu: "Open the menu",
      closeMenu: "Close the menu",
      logo: "logo"
    }
  },
  init: function init(header) {
    this.headerEl = header;
    this.setMenuRoles();
    this.createEvents();
    this.setTabindex();
    this.setAriaLabels();
    this.setAriaExpanded();
  },
  setMenuRoles: function setMenuRoles() {
    $(this.menuItems).attr("role", "menuItem");
    $(this.closeButton).attr("role", "button");
    $(this.menuLists).attr("role", "menubar");
    $(this.offRole).attr("role", "menuitem");
  },
  setAriaLabels: function setAriaLabels() {
    var _this2 = this;
    var language = $("html").attr("lang");
    window.DGS.Dictionary.getInstance().getTexts(this.defaultDictionary, function () {
      var _this2$defaultDiction = _this2.defaultDictionary.Navigation,
        logo = _this2$defaultDiction.logo,
        openMenu = _this2$defaultDiction.openMenu,
        closeMenu = _this2$defaultDiction.closeMenu;
      $(_this2.closeButton).attr("aria-label", closeMenu);
      $(_this2.logo).attr("aria-label", logo);
      $(_this2.navExpander).attr("aria-label", openMenu);
      $(_this2.navExpander).on("click", function () {
        var label = $(_this2.navExpander).hasClass("is-active") ? closeMenu : openMenu;
        $(_this2.navExpander).attr("aria-label", label);
      });
    }, language);
  },
  manageFocusOnMouseUse: function manageFocusOnMouseUse() {
    $("body").removeClass("user-is-tabbing");
  },
  manageFocusOnTabKeyUse: function manageFocusOnTabKeyUse(e) {
    if (e.keyCode === 9) {
      $("body").addClass("user-is-tabbing");
    }
  },
  createEvents: function createEvents() {
    var _this = this;
    $("body").on("click", function (e) {
      _this.manageFocusOnMouseUse();
    });
    $("body").on("keydown", function (e) {
      _this.manageFocusOnTabKeyUse(e);
    });
    $(".level2.has-children").on("click", function (e) {
      var activeSubmenu = e.currentTarget;
      _this.activeSubmenu.current = activeSubmenu;
    });
  },
  setAriaExpanded: function setAriaExpanded() {
    $(".sticky-navigation .level2 > ul").attr({
      "aria-haspopup": "true",
      "aria-expanded": "false",
      "aria-hidden": "true"
    });
    $(".sticky-navigation .level2.is-active > ul").attr({
      "aria-expanded": "true",
      "aria-hidden": "false"
    });
  },
  setTabindex: function setTabindex() {
    $(".sticky-navigation .level2 > a").attr("tabindex", 4);
    $("#content a").attr("tabindex", 5);
    $("#footer a").attr("tabindex", 6);
    $("#header .section-one a").attr("tabindex", 1);
    $("#header .section-two a").attr("tabindex", 2);
    $(".sticky-navigation .inner-level3 a").attr("tabindex", -1);
  },
  tabIndexChanger: function tabIndexChanger(activeSubmenu) {
    $(activeSubmenu).find("a").attr("tabindex", 3);
    $(activeSubmenu).find(".close-button").attr("tabindex", 3);
    $(".sticky-navigation .level2:not(.is-active) .level3 a,.sticky-navigation .level2:not(.is-active) .level3 .close-button").attr("tabindex", -1);
    this.setAriaExpanded();
  }
};
export { accesibility };