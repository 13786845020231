/**
 * Created by mady on 12-07-2016.
 */
(function () {
  /*
  TODO: strict mode error introduced temperarily on purpose (no var before DFS) to make JS
  bundling fail on the server and avoid the JS bundle being minimized (as it causes errors and
  stops sites from functioning) as described in http://jira.kitenet.com/browse/OD-4617.
  */
  DGS = window.DGS || {};
  DGS.Events = DGS.Events || {};
  $.extend(DGS.Events, {
    RESIZE_DONE: "resizeDone",
    SELECT_ADDED: "selectAdded",
    SELECT_UPDATED: "selectUpdated",
    BUILDER_DONE: "builderDone"
  });
})();