function cloneElements(component, targetCssSelector, cssClassToAppend) {
  var tagList = Array.from(component.querySelectorAll(targetCssSelector));
  var clonedElements = [];
  tagList.forEach(function (tag) {
    var clonedElement = tag.cloneNode(true);
    if (cssClassToAppend) {
      clonedElement.classList.add(cssClassToAppend);
    }
    clonedElements.push(clonedElement);
  });
  return clonedElements;
}
;
function blockClickEvents(component, targetCssSelector) {
  var liTagList = Array.from(component.querySelectorAll(targetCssSelector));
  liTagList.forEach(function (liTag) {
    liTag.addEventListener('click', function (event) {
      event.preventDefault();
    });
  });
}
;
function toggleActiveClassOnClick(component, targetCssSelector, cssClassName, callback) {
  var aTagList = Array.from(component.querySelectorAll(targetCssSelector));
  aTagList.forEach(function (aTag) {
    aTag.addEventListener('click', function () {
      var liTag = aTag.parentNode;
      var elToCalculate = liTag.querySelectorAll('.inner-level3 .level3');
      resetClassForItemsSameLevel(liTag, cssClassName);
      if (checkMegaMenuExceptions(elToCalculate)) {
        megaMenuFunction(liTag, elToCalculate);
      }
      liTag.classList.toggle(cssClassName);
      if (callback) {
        callback.apply(null, null);
      }
    });
  });
}
;
function findHighestElement(elToCalculate) {
  var highest = 0;
  elToCalculate.forEach(function (el) {
    highest = el.offsetHeight > highest ? el.offsetHeight : highest;
  });
  return highest;
}
;
function megaMenuFunction(liTag, elToCalculate) {
  var submenuWrapper = liTag.querySelectorAll('.inner-level3')[0];
  var exceptionHeight = findHighestElement(elToCalculate);
  var fixClassMegaMenu = 'mega-menu-fix';
  submenuWrapper.style.maxHeight = exceptionHeight + 'px';
  submenuWrapper.style.height = exceptionHeight + 'px';
  submenuWrapper.classList.add(fixClassMegaMenu);
}
;
function checkMegaMenuExceptions(elToCalculate) {
  return window.innerWidth >= 992 && elToCalculate.length > 5 ? true : false;
}
;
function resetClassForItemsSameLevel(targetItem, cssClassName) {
  var siblings = Array.from(targetItem.parentNode.childNodes);
  siblings.forEach(function (sibling) {
    if (sibling.nodeType == Node.ELEMENT_NODE && sibling !== targetItem) {
      sibling.classList.remove(cssClassName);
    }
  });
}
;
export { cloneElements, blockClickEvents, toggleActiveClassOnClick };