function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var cssCustomLoader = function cssCustomLoader() {
  var cssChunks = _defineProperty(_defineProperty({
    '#wrapper': 'legacy',
    '.locations-spot': 'locations-spot',
    '.pep-product-list, .pep-accessories-list, .pep-product-details, .pep-banner, .pep-guide': 'pep',
    '.flex-spot, .general-brainhearing, .pim-compatibility-guide, .general-oticon-more, .custom-banner': 'flex-spot',
    '.spare-parts-spot': 'spare-parts-spot',
    '.hearing-test, .hearing-test-spot': 'hearing-test',
    '.resource-center-new': 'resource-center-new',
    '.countdown-timer': 'countdown-timer',
    '.batteryshop-product-category, .component.batteryshop, #SelectedProducts, .order-confirmation-columns, .batteryshop-orderconfirmation': 'batteryshop',
    '.product-order, .fr-hide-fields': 'product-order',
    '.payment-progress-spot': 'payment-progress-spot',
    '.data-hierarchy-spot': 'data-hierarchy-spot',
    '.product-tool-container, .product-tool-conntainer': 'product-tool',
    '.form, .login, .form-confirmation': 'form',
    '.text-image-spot': 'text-image-spot',
    '.data-list-spot, .data-list-search-spot': 'data-list-spot',
    '.link-list': 'link-list',
    '.download-center': 'download-center',
    '.hearing-center-locator, #map-canvas': 'hearing-center-locator'
  }, ".locations-spot", 'locations-spot'), '.phones-compatibility', 'phones-compatibility');
  Object.keys(cssChunks).map(function (key) {
    var element = document.querySelector(key);
    var link = document.querySelector("link[data-component^=\"".concat(cssChunks[key], "\"]"));
    if (element && link) {
      if (cssChunks[key] === 'legacy') {
        link.setAttribute('media', 'print');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('onload', 'this.media="all"');
      }
      link.setAttribute('href', link.getAttribute('data-href'));
    }
  });
};
export default cssCustomLoader;